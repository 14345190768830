<template>
  <match-media v-slot="{ desktop }">
    <v-table-row class="articles-item" @click="() => desktop && edit()">
      <v-square-avatar class="articles-item__cell articles-item__cell--image" :src="previewImage" />
      <div class="articles-item__content">
        <div class="articles-item__cell articles-item__cell--title">
          {{ title }}
        </div>
        <div class="articles-item__cell articles-item__cell--author">
          {{ author }}
        </div>
        <div class="articles-item__cell articles-item__cell--date">{{ createdAtString }}</div>
      </div>
      <div class="articles-item__actions" @click.stop>
        <template v-if="desktop">
          <v-button-icon v-if="isActive" class="articles-item__action" @click="archive">
            <v-icon-archive />
          </v-button-icon>
          <v-button-icon v-if="isArchived" class="articles-item__action" @click="activate">
            <v-icon-activate />
          </v-button-icon>
          <v-button-icon v-if="isDraft" class="articles-item__action" @click="remove">
            <v-icon-trash-bin />
          </v-button-icon>
        </template>
        <v-popover-actions v-else class="articles-item__mobile-actions">
          <v-button-icon class="articles-item__action">
            <v-icon-dots />
          </v-button-icon>
          <template #actions="{ onClick }">
            <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
            <v-button-wide v-if="isActive" @click="onClick(archive)">Архивировать</v-button-wide>
            <v-button-wide v-if="isArchived" @click="onClick(activate)">Активировать</v-button-wide>
            <v-button-wide v-if="isDraft" @click="onClick(remove)">Удалить</v-button-wide>
          </template>
        </v-popover-actions>
      </div>
    </v-table-row>
  </match-media>
</template>

<script>
import VTableRow from '@/components/common/VTableRow.vue'
import { MatchMedia } from 'vue-component-media-queries'
import VIconArchive from '@/components/icons/VArchive.vue'
import VIconActivate from '@/components/icons/VActivate.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VIconTrashBin from '@/components/icons/VTrashBin.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VSquareAvatar from '@/components/common/VSquareAvatar.vue'
import { ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_ARCHIVE, ARTICLE_STATUS_DRAFT } from '@/constants/statuses/article'
import { formatDateTime } from '@/utils/formatters'

export default {
  name: 'ArticlesListItem',
  components: {
    VSquareAvatar,
    VTableRow,
    MatchMedia,
    VIconArchive,
    VIconActivate,
    VButtonWide,
    VPopoverActions,
    VIconDots,
    VIconTrashBin,
    VButtonIcon
  },
  props: {
    author: { type: String, default: null },
    title: { type: String, default: null },
    previewImage: { type: String, default: null },
    status: { type: String, required: true },
    createdAt: { type: String, default: null }
  },
  computed: {
    isActive() {
      return this.status === ARTICLE_STATUS_ACTIVE
    },
    isArchived() {
      return this.status === ARTICLE_STATUS_ARCHIVE
    },
    isDraft() {
      return this.status === ARTICLE_STATUS_DRAFT
    },
    createdAtString() {
      return formatDateTime(this.createdAt, '.')
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    archive() {
      this.$emit('archive')
    },
    activate() {
      this.$emit('activate')
    },
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
