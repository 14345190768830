<template>
  <v-drawer :visible.sync="localVisible">
    <div class="articles-filters-mobile">
      <div
        :class="[
          'articles-filters-mobile__header',
          activeMenuItem && 'articles-filters-mobile__header--active-section'
        ]"
      >
        <button v-if="!activeMenuItem" class="articles-filters-mobile__close-button" @click="close">
          <v-icon-cross />
        </button>
        <button v-else class="articles-filters-mobile__close-button" @click="goBack">
          <v-icon-arrow-left />
        </button>
        <div class="articles-filters-mobile__header-info">
          Фильтры
          <div v-if="activeMenuItem" class="articles-filters-mobile__menu-item-name">
            {{ $options.NAME_MENU_ITEMS[activeMenuItem] }}
          </div>
        </div>
        <v-button-wide v-if="!activeMenuItem" class="articles-filters-mobile__reset-button" @click="resetFilters">
          Сброс
        </v-button-wide>
      </div>

      <div class="articles-filters-mobile__menu">
        <v-menu v-if="!activeMenuItem" v-model="activeMenuItem">
          <v-menu-item :name="$options.CATEGORY">
            Категория
            <span class="articles-filters-mobile__menu-item-preview"> {{ preview.category }} </span>
          </v-menu-item>
          <v-menu-item :name="$options.YEAR">
            Год
            <span class="articles-filters-mobile__menu-item-preview"> {{ filtersParams.year }} </span>
          </v-menu-item>
        </v-menu>
      </div>

      <div class="articles-filters-mobile__sections-wrapper">
        <div v-if="activeMenuItem === $options.CATEGORY" class="articles-filters-mobile__select">
          <filters-select
            v-model="selectedCategory"
            :options="categoryOptions"
            class="articles-filters-mobile__list-item"
          />
        </div>
        <div v-if="activeMenuItem === $options.YEAR" class="articles-filters-mobile__select">
          <filters-select v-model="selectedYear" :options="yearOptions" class="articles-filters-mobile__list-item" />
        </div>
      </div>
      <v-button v-if="!activeMenuItem" class="articles-filters-mobile__button" @click="applyFilters">
        Применить
      </v-button>
    </div>
  </v-drawer>
</template>

<script>
import VDrawer from '@/components/common/VDrawer.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VIconCross from '@/components/icons/VCross.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VButton from '@/components/common/VButton.vue'
import FiltersSelect from '@/components/Agent/AgentsSelect.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'
import { articlesService } from '@/services/http'
import { YEAR, CATEGORY, NAME_MENU_ITEMS } from '@/constants/articles'

function generateFilters() {
  return {
    categoryId: undefined,
    year: undefined
  }
}

function generatePreview() {
  return {
    category: null
  }
}

export default {
  NAME_MENU_ITEMS,
  YEAR,
  CATEGORY,
  name: 'ArticlesFiltersMobile',
  components: {
    VDrawer,
    VIconCross,
    VButtonWide,
    VMenu,
    VMenuItem,
    VIconArrowLeft,
    VButton,
    FiltersSelect
  },
  props: {
    visible: { type: Boolean, required: true },
    defaultFilters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      yearOptions: [],
      activeMenuItem: undefined,
      categoryOptions: [],
      yearOptionsLoading: false,
      categoryOptionsLoading: false,
      filtersParams: generateFilters(),
      preview: generatePreview()
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    selectedCategory: {
      get() {
        return { id: this.filtersParams.categoryId }
      },
      set(category) {
        this.filtersParams.categoryId = category?.id
        this.preview.category = category?.name
      }
    },
    selectedYear: {
      get() {
        return { id: this.filtersParams.year }
      },
      set(year) {
        this.filtersParams.year = year?.name
      }
    }
  },
  watch: {
    defaultFilters: {
      immediate: true,
      handler({ categoryId, year }) {
        this.filtersParams = { categoryId, year }
      }
    },
    categoryOptions() {
      this.preview.category = this.categoryOptions.find(category => category.id === this.filtersParams.categoryId)?.name
    }
  },
  created() {
    this.selectYears()
    this.selectCategory()
  },
  methods: {
    selectYears() {
      this.yearOptionsLoading = true
      return articlesService
        .selectYears()
        .then(response => {
          this.yearOptions = response.years.map(year => {
            return {
              id: year,
              name: year
            }
          })
        })
        .finally(() => {
          this.yearOptionsLoading = false
        })
    },
    selectCategory() {
      this.categoryOptionsLoading = true
      return articlesService
        .selectCategory()
        .then(categories => {
          this.categoryOptions = categories
        })
        .finally(() => {
          this.categoryOptionsLoading = false
        })
    },
    resetFilters() {
      this.filtersParams = generateFilters()
      this.preview = generatePreview()
    },
    applyFilters() {
      this.$emit('apply-filters', { ...this.filtersParams })
    },
    goBack() {
      this.activeMenuItem = undefined
    },
    close() {
      this.localVisible = false
    }
  }
}
</script>
