<template>
  <div class="articles-filters-desktop">
    <div class="articles-filters-desktop__title">Фильтры</div>
    <div class="articles-filters-desktop__body">
      <v-form-field label="КАТЕГОРИЯ СТАТЕЙ" class="articles-filters-desktop__field">
        <v-select
          v-model="filtersParams.categoryId"
          :loading="categoryOptionsLoading"
          class="articles-filters-desktop__select"
          :reduce="option => option.value"
          placeholder="Все"
          append-to-body
          inputmode="none"
          :clearable="false"
          :options="categoryOptions"
        />
      </v-form-field>
      <v-form-field label="ГОД" class="articles-filters-desktop__field">
        <v-select
          v-model="filtersParams.year"
          :loading="yearOptionsLoading"
          class="articles-filters-desktop__select"
          placeholder="Все"
          append-to-body
          inputmode="none"
          :clearable="false"
          :options="yearOptions"
        />
      </v-form-field>
    </div>
    <v-button class="articles-filters-desktop__button" @click="search">
      Показать найденные статьи
    </v-button>
  </div>
</template>

<script>
import VSelect from '@/components/common/VSelect.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import { articlesService } from '@/services/http'

export default {
  name: 'ArticlesFiltersDesktop',
  components: {
    VSelect,
    VFormField,
    VButton
  },
  inheritAttrs: false,
  props: {
    defaultFilters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      filtersParams: {
        year: undefined,
        categoryId: undefined
      },
      categoryOptionsLoading: false,
      categoryOptions: [],
      yearOptionsLoading: false,
      yearOptions: []
    }
  },
  watch: {
    defaultFilters({ categoryId, year }) {
      this.filtersParams = { categoryId, year }
    }
  },
  created() {
    this.selectYears()
    this.selectCategory()
  },
  methods: {
    selectCategory() {
      this.categoryOptionsLoading = true
      return articlesService
        .selectCategory()
        .then(categories => {
          categories.unshift({
            name: 'Все',
            id: null
          })
          this.categoryOptions = categories.map(category => {
            return {
              label: category.name,
              value: category.id
            }
          })
        })
        .finally(() => {
          this.categoryOptionsLoading = false
        })
    },
    selectYears() {
      this.yearOptionsLoading = true
      return articlesService
        .selectYears()
        .then(response => {
          const { years } = response
          response.years.unshift('Все')
          this.yearOptions = years
        })
        .finally(() => {
          this.yearOptionsLoading = false
        })
    },
    search() {
      this.$emit('search', this.filtersParams)
    }
  }
}
</script>
