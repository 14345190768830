<template>
  <match-media v-slot="{ desktop, tablet, mobile }">
    <filters-mobile
      v-if="!desktop"
      class="articles__filters-mobile"
      :default-filters="queryParams"
      :visible.sync="filtersVisible"
      @apply-filters="searchByFilters"
    />
    <div class="articles">
      <div class="articles__header">
        <v-page-title class="articles__title">
          Блог
        </v-page-title>
        <div class="articles__buttons">
          <v-button primary class="articles__button" @click="addArticle">
            <v-icon-plus class="articles__button-icon" />
            Добавить Статью
          </v-button>
          <v-button v-if="tablet" primary class="articles__button-filters" @click="toggleFilters">
            <v-icon-filters class="articles__button-icon" />
          </v-button>
        </div>
      </div>
      <div class="articles__main">
        <div v-if="desktop" class="articles__filters-wrapper">
          <articles-filters-desktop
            :default-filters="queryParams"
            class="articles__filters-desktop"
            @search="searchByFilters"
          />
        </div>
        <div class="articles__body">
          <div class="articles__tabs-wrapper">
            <v-tabs :value="queryParams.status" class="articles__tabs" :primary="mobile" @input="changeStatus">
              <v-tab
                v-for="(articleStatus, index) of $options.ARTICLES_STATUSES"
                :key="index"
                :name="articleStatus.value"
              >
                {{ articleStatus.label }}
              </v-tab>
            </v-tabs>
          </div>
          <v-searchrow :value="queryParams.query" class="articles__search" @search="search" />
          <div v-if="mobile" class="articles__button-wrapper-mobile">
            <v-button class="articles__button articles__button" @click="toggleFilters">
              <v-icon-filters class="articles__button-icon articles__button-icon--filters" />
              Фильтры и сортировка
            </v-button>
          </div>
          <section class="articles__list">
            <articles-list-item
              v-for="article in articlesList"
              :key="article.id"
              v-bind="article"
              @edit="editArticle(article)"
              @archive="archiveArticle(article)"
              @activate="activateArticle(article)"
              @remove="removeArticle(article)"
            />
          </section>
          <div class="articles__pagination">
            <v-pagination :current="queryParams.page" :total="totalPages" @change="changePage" />
          </div>
        </div>
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import ArticlesFiltersDesktop from '@/components/Articles/Filters/Desktop.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import ArticlesListItem from '@/components/Articles/ListItem.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import VPagination from '@/components/common/VPagination.vue'
import FiltersMobile from '@/components/Articles/Filters/Mobile.vue'
import VIconFilters from '@/components/icons/VFilters.vue'
import { ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_ARCHIVE, ARTICLE_STATUS_DRAFT } from '@/constants/statuses/article'
import { loadingService } from '@/services/loading'
import { articlesService } from '@/services/http'
import confirmService from '@/services/confirmation'
import noticeService from '@/services/notification'
// eslint-disable-next-line import/no-cycle
import redirectIfNetworkIssue from '@/router/utils'

const STATUSES_MAP = [
  { label: 'Актуальное', value: ARTICLE_STATUS_ACTIVE },
  { label: 'Черновики', value: ARTICLE_STATUS_DRAFT },
  { label: 'Архив', value: ARTICLE_STATUS_ARCHIVE }
]

export default {
  ARTICLES_STATUSES: STATUSES_MAP,

  name: 'Articles',
  components: {
    MatchMedia,
    ArticlesFiltersDesktop,
    VPageTitle,
    VButton,
    VIconPlus,
    VIconFilters,
    FiltersMobile,
    VTabs,
    VTab,
    ArticlesListItem,
    VSearchrow,
    VPagination
  },
  data() {
    return {
      loading: false,
      list: [],
      filtersVisible: false,
      params: {
        limit: 15,
        count: 0
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchArticles()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.fetchArticles()
    })
    next()
  },
  computed: {
    totalPages() {
      return Math.ceil(this.params.count / this.params.limit)
    },
    queryParams() {
      return {
        page: Number(this.$route.query.page) || 1,
        status: this.$route.query.status || ARTICLE_STATUS_ACTIVE,
        query: this.$route.query.query || undefined,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        year: Number(this.$route.query.year) || undefined
      }
    },
    articlesList() {
      return this.list.map(article => ({ ...article, status: this.queryParams.status }))
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    },
    queryParams: {
      immediate: true,
      handler(newQueryParams) {
        this.filtersParams = { categoryId: newQueryParams.categoryId, year: newQueryParams.year }
      }
    }
  },
  methods: {
    updateQueryParams({ page, ...otherParams }) {
      this.$router.push({ query: { ...this.queryParams, ...{ page, ...otherParams } } })
    },
    addArticle() {
      this.$router.push({ name: 'articles-edit' })
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    changePage(page) {
      this.updateQueryParams({ page })
    },
    search(query) {
      this.updateQueryParams({ query })
    },
    searchByFilters(filtersParams) {
      this.filtersVisible = false
      this.updateQueryParams(filtersParams)
    },
    toggleFilters() {
      this.filtersVisible = true
    },
    fetchArticles() {
      const { page } = this.queryParams
      const { limit } = this.params
      const offset = (page - 1) * limit
      this.loading = true
      return articlesService
        .getList({ limit, offset, search: this.queryParams.query, ...this.queryParams })
        .then(({ count, results }) => {
          this.list = results
          this.params = { ...this.params, count }
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
        })
    },
    editArticle(article) {
      this.$router.push({ name: 'articles-edit', params: { articleId: article.id } })
    },
    archiveArticle(article) {
      confirmService
        .ask({ title: 'Вы точно хотите архивировать статью?' })
        .then(() => {
          this.loading = true
          articlesService
            .updateStatus(article.id, ARTICLE_STATUS_ARCHIVE)
            .then(() => {
              this.fetchArticles()
              noticeService.success('Статья архивирована!')
            })
            .catch(() => noticeService.error('Не удалось архивировать статью!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    activateArticle(article) {
      confirmService
        .ask({ title: 'Вы точно хотите активировать статью?' })
        .then(() => {
          this.loading = true

          articlesService
            .updateStatus(article.id, ARTICLE_STATUS_ACTIVE)
            .then(() => {
              this.fetchArticles()
              noticeService.success('Статья активирована!')
            })
            .catch(() => noticeService.error('Не удалось активировать статью!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    removeArticle(article) {
      confirmService
        .ask({ title: 'Вы точно хотите удалить статью?' })
        .then(() => {
          this.loading = true
          articlesService
            .delete(article.id)
            .then(() => {
              this.fetchArticles()
              noticeService.success('Статья удалена!')
            })
            .catch(() => noticeService.error('Не удалось удалить статью!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
